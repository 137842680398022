import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="magic-items"></a><h2>Magic Items</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Creating Magic Items</span>
    </h2>
    <p>To create magic items, spellcasters use special feats. They
invest time, money, and their own personal energy (in the form of
experience points) in an item&#8217;s creation.</p>
    <p>Note that all items have prerequisites in their descriptions.
These prerequisites must be met for the item to be created. Most of the
time, they take the form of spells that must be known by the item&#8217;s
creator (although access through another magic item or spellcaster is
allowed).</p>
    <p>While item creation costs are handled in detail below, note
that normally the two primary factors are the caster level of the
creator and the level of the spell or spells put into the item. A
creator can create an item at a lower caster level than her own, but
never lower than the minimum level needed to cast the needed spell.
Using metamagic feats, a caster can place spells in items at a higher
level than normal.</p>
    <p>Magic supplies for items are always half of the base price in
gp and 1/25 of the base price in XP. For many items, the market price
equals the base price.</p>
    <p>Armor, shields, weapons, and items with a value independent of
their magically enhanced properties add their item cost to the market
price. The item cost does not influence the base price (which
determines the cost of magic supplies and the experience point cost),
but it does increase the final market price.</p>
    <p>In addition, some items cast or replicate spells with costly
material components or with XP components. For these items, the market
price equals the base price plus an extra price for the spell component
costs. Each XP in the component costs adds 5 gp to the market price.
The cost to create these items is the magic supplies cost and the base
XP cost (both determined by the base price) plus the costs for the
components. Descriptions of these items include an entry that gives the
total cost of creating the item.</p>
    <p>The creator also needs a fairly quiet, comfortable, and
well-lit place in which to work. Any place suitable for preparing
spells is suitable for making items. Creating an item requires one day
per 1,000 gp in the item&#8217;s base price, with a minimum of at least one
day. Potions are an exception to this rule; they always take just one
day to brew. The character must spend the gold and XP at the beginning
of the construction process.</p>
    <p>The caster works for 8 hours each day. He cannot rush the
process by working longer each day. But the days need not be
consecutive, and the caster can use the rest of his time as he sees fit.</p>
    <p>A character can work on only one item at a time. If a
character starts work on a new item, all materials used and XP spent on
the under-construction item are wasted.</p>
    <p>The secrets of creating artifacts are long lost.</p>
    <a id="table-summary-of-magic-item-creation-costs"></a><p><b>Table: SUMMARY OF MAGIC ITEM CREATION COSTS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th colSpan="3">
          </th>
          <th colSpan="2" className="center">Spell Component Costs</th>
          <th colSpan="2">
          </th>
        </tr>
        <tr valign="bottom">
          <th>Magic Item</th>
          <th>Feat</th>
          <th>Item Cost</th>
          <th>Material<sup>2</sup></th>
          <th>XP<sup>3</sup></th>
          <th>Magic Supplies Cost</th>
          <th>Base Price<sup>4</sup></th>
        </tr>
        <tr className="odd-row">
          <td>Armor</td>
          <td>Craft Magic Arms and Armor</td>
          <td>Masterwork armor</td>
          <td>Cost x 50 (usually none)</td>
          <td>x 50 (usually none) x 5 gp</td>
          <td>1/2 the value on Table: Armor and Shields</td>
          <td>Value on Table: Armor and Shields</td>
        </tr>
        <tr>
          <td>Shield</td>
          <td>Craft Magic Arms and Armor</td>
          <td>Masterwork shield</td>
          <td>x 50 (usually none)</td>
          <td>x 50 (usually none) x 5 gp</td>
          <td>1/2 the value on Table: Armor and Shields</td>
          <td>Value on Table: Armor and Shields</td>
        </tr>
        <tr className="odd-row">
          <td>Weapon</td>
          <td>Craft Magic Arms and Armor</td>
          <td>Masterwork weapon</td>
          <td>x 50 (usually none)</td>
          <td>x 50 (usually none) x 5 gp</td>
          <td>1/2 the value on Table: Weapons</td>
          <td>Value on Table: Weapons</td>
        </tr>
        <tr>
          <td>Potion</td>
          <td>Brew Potion</td>
          <td> &#8212;</td>
          <td>Cost (usually none)</td>
          <td>Cost (usually none)</td>
          <td>1/2 x 50 x level of spell x level of caster</td>
          <td>50 x level of spell x level of caster</td>
        </tr>
        <tr className="odd-row">
          <td>Ring</td>
          <td>Forge Ring</td>
          <td>&#8212;</td>
          <td> x 50</td>
          <td>x 50 x 5 gp</td>
          <td>Special, see Table: Estimating Magic Item Gold Price
Values, below</td>
          <td>Special, see Table: Estimating Magic Item Gold Price
Values, below</td>
        </tr>
        <tr>
          <td>Rod</td>
          <td>Craft Rod</td>
          <td><sup>1</sup></td>
          <td>x 50 (often none)</td>
          <td>x 50 (often none)</td>
          <td>Special, see Table: Estimating Magic Item Gold Price
Values, below</td>
          <td>Special, see Table: Estimating Magic Item Gold Price
Values, below</td>
        </tr>
        <tr className="odd-row">
          <td>Scroll</td>
          <td>Scribe Scroll</td>
          <td>&#8212;</td>
          <td>Cost (usually none)</td>
          <td>Cost (usually none)</td>
          <td>1/2 x 25 x level of spell x level of caster</td>
          <td>25 x level of spell x level of caster</td>
        </tr>
        <tr>
          <td>Staff</td>
          <td>Craft Staff</td>
          <td>Masterwork quarterstaff (300 gp)</td>
          <td>x 50 / (# of charges used to activate spell)</td>
          <td>x 50 x 5 gp / (# of charges used to activate spell)</td>
          <td>See Creating Staffs, below</td>
          <td>See Creating Staffs, below</td>
        </tr>
        <tr className="odd-row">
          <td>Wand</td>
          <td>Craft Wand</td>
          <td>&#8212;</td>
          <td>x 50</td>
          <td>x 50 x 5 gp</td>
          <td>1/2 x 750 x level of spell x level of caster</td>
          <td>750 x level of spell x level of caster</td>
        </tr>
        <tr>
          <td className="last-row">Wondrous Item</td>
          <td className="last-row">Craft Wondrous Item</td>
          <td className="last-row"><sup>5</sup></td>
          <td className="last-row">x 50 (usually none)</td>
          <td className="last-row">x 50 (usually none) x 5 gp</td>
          <td className="last-row">Special, see Table: Estimating Magic
Item Gold Price Values, below</td>
          <td className="last-row">Special, see Table: Estimating Magic
Item Gold Price Values, below</td>
        </tr>
        <tr>
          <td colSpan="7">1 <i>Rods usable as weapons must include
the masterwork weapon cost.</i></td>
        </tr>
        <tr>
          <td colSpan="7">2 <i>This cost is only for spells
activated by the item that have material or XP components. Having a
spell with a costly component as a prerequisite does not automatically
incur this cost if the item doesn&#8217;t actually cast the spell. </i></td>
        </tr>
        <tr>
          <td colSpan="7">3 <i>If purchasing a staff, the buyer pays
5 x the XP value in gold pieces.</i></td>
        </tr>
        <tr>
          <td colSpan="7">4 <i>A character creating an item pays
1/25 the base price in experience points.</i></td>
        </tr>
        <tr>
          <td colSpan="7">5 <i>Some items have additional value from
a masterwork item component. </i></td>
        </tr>
        <tr>
          <td colSpan="7"><b><i>An item&#8217;s market price is the sum of
the item cost, spell component costs, and the base price.</i></b></td>
        </tr>
      </tbody>
    </table>
    <a id="table-estimating-magic-item-gold-piece-values"></a><p><b><a id="table-estimating-values"></a>Table: ESTIMATING
MAGIC ITEM GOLD PIECE VALUES</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th>Effect </th>
          <th>Base Price </th>
          <th>Example</th>
        </tr>
        <tr className="odd-row">
          <td>Ability bonus (enhancement)</td>
          <td>Bonus squared x 1,000 gp</td>
          <td>Gloves of Dexterity +2</td>
        </tr>
        <tr>
          <td>Armor bonus (enhancement)</td>
          <td>Bonus squared x 1,000 gp</td>
          <td>+1 chainmail</td>
        </tr>
        <tr className="odd-row">
          <td>Bonus spell</td>
          <td>Spell level squared x 1,000 gp</td>
          <td>Pearl of power</td>
        </tr>
        <tr>
          <td>AC bonus (deflection)</td>
          <td>Bonus squared x 2,000 gp</td>
          <td>Ring of protection +3</td>
        </tr>
        <tr className="odd-row">
          <td>AC bonus (other)<sup>1</sup></td>
          <td>Bonus squared x 2,500 gp</td>
          <td>Ioun stone, dusty rose prism</td>
        </tr>
        <tr>
          <td>Natural armor bonus (enhancement)</td>
          <td>Bonus squared x 2,000 gp</td>
          <td>Amulet of natural armor +1</td>
        </tr>
        <tr className="odd-row">
          <td>Save bonus (resistance)</td>
          <td>Bonus squared x 1,000 gp</td>
          <td>Cloak of resistance +5</td>
        </tr>
        <tr>
          <td>Save bonus (other)<sup>1</sup></td>
          <td>Bonus squared x 2,000 gp</td>
          <td>Stone of good luck</td>
        </tr>
        <tr className="odd-row">
          <td>Skill bonus (competence)</td>
          <td>Bonus squared x 100 gp</td>
          <td>Cloak of elvenkind</td>
        </tr>
        <tr>
          <td>Spell resistance</td>
          <td>10,000 gp per point over SR 12; SR 13 minimum</td>
          <td>Mantle of spell resistance</td>
        </tr>
        <tr className="odd-row">
          <td>Weapon bonus (enhancement)</td>
          <td>Bonus squared x 2,000 gp</td>
          <td>+1 longsword</td>
        </tr>
        <tr>
          <th>Spell Effect </th>
          <th>Base Price </th>
          <th>Example</th>
        </tr>
        <tr className="odd-row">
          <td>Single use, spell completion</td>
          <td>Spell level x caster level x 25 gp</td>
          <td>Scroll of haste</td>
        </tr>
        <tr>
          <td>Single use, use-activated</td>
          <td>Spell level x caster level x 50 gp</td>
          <td>Potion of cure light wounds</td>
        </tr>
        <tr className="odd-row">
          <td>50 charges, spell trigger</td>
          <td>Spell level x caster level x 750 gp</td>
          <td>Wand of fireball</td>
        </tr>
        <tr>
          <td>Command word</td>
          <td>Spell level x caster level x 1,800 gp</td>
          <td>Cape of the mountebank</td>
        </tr>
        <tr className="odd-row">
          <td>Use-activated or continuous</td>
          <td>Spell level x caster level x 2,000 gp<sup>2</sup></td>
          <td>Lantern of revealing</td>
        </tr>
        <tr>
          <th>Special </th>
          <th>Base Price Adjustment </th>
          <th>Example</th>
        </tr>
        <tr className="odd-row">
          <td>Charges per day</td>
          <td>Divide by (5 divided by charges per day)</td>
          <td>Boots of teleportation</td>
        </tr>
        <tr>
          <td>Uncustomary space limitation<sup>3</sup></td>
          <td>Multiply entire cost by 1.5</td>
          <td>Helm of teleportation</td>
        </tr>
        <tr className="odd-row">
          <td>No space limitation<sup>4</sup></td>
          <td>Multiply entire cost by 2</td>
          <td>Ioun stone</td>
        </tr>
        <tr>
          <td>Multiple different abilities</td>
          <td>Multiply lower item cost by 1.5
          </td>
          <td>Helm of brilliance</td>
        </tr>
        <tr className="odd-row">
          <td>Charged (50 charges)</td>
          <td>1/2 unlimited use base price</td>
          <td>Ring of the ram</td>
        </tr>
        <tr>
          <th>Component </th>
          <th>Extra Cost </th>
          <th>Example</th>
        </tr>
        <tr className="odd-row">
          <td>Armor, shield, or weapon</td>
          <td>Add cost of masterwork item</td>
          <td>+1 composite longbow</td>
        </tr>
        <tr>
          <td>Spell has material component cost</td>
          <td>Add directly into price of item per charge<sup>5</sup></td>
          <td>Wand of stoneskin</td>
        </tr>
        <tr className="odd-row">
          <td>Spell has XP cost</td>
          <td>Add 5 gp per 1 XP per charge<sup>5</sup></td>
          <td>Ring of three wishes</td>
        </tr>
        <tr>
          <td colSpan="3"><i>Spell Level</i>: A 0-level spell is half
the value of a 1st-level spell for determining price.</td>
        </tr>
        <tr>
          <td colSpan="3">1 Such as a luck, insight, sacred, or
profane bonus.</td>
        </tr>
        <tr>
          <td colSpan="3">2 If a continuous item has an effect based
on a spell with a duration measured in rounds, multiply the cost by 4.
If the duration of the spell is 1 minute/level, multiply the cost by 2,
and if the duration is 10 minutes/level, multiply the cost by 1.5. If
the spell has a 24-hour duration or greater, divide the cost in half.</td>
        </tr>
        <tr>
          <td colSpan="3">3 See Body Slot Affinities, below.</td>
        </tr>
        <tr>
          <td colSpan="3">4 An item that does not take up one of the
spaces on a body costs double.</td>
        </tr>
        <tr>
          <td colSpan="3">5 If item is continuous or unlimited, not
charged, determine cost as if it had 100 charges. If it has some daily
limit, determine as if it had 50 charges.</td>
        </tr>
      </tbody>
    </table>
    <a id="magic-item-gold-piece-values"></a><h5>MAGIC ITEM GOLD PIECE VALUES</h5>
    <p className="initial">Many factors must be considered when
determining the price of new magic items. The easiest way to come up
with a price is to match the new item to an item that is already priced
that price as a guide. Otherwise, use the guidelines summarized on
Table: Estimating Magic Item Gold Piece Values.</p>
    <a id="multiple-similar-abilities"></a><p><b>Multiple Similar Abilities</b>: For items with multiple
similar abilities that don&#8217;t take up space on a character&#8217;s body use
the following formula: Calculate the price of the single most costly
ability, then add 75% of the value of the next most costly ability,
plus one-half the value of any other abilities.</p>
    <a id="multiple-different-abilities"></a><p><b>Multiple Different Abilities</b>: Abilities such as an
attack roll bonus or saving throw bonus and a spell-like function are
not similar, and their values are simply added together to determine
the cost. For items that do take up a space on a character&#8217;s body each
additional power not only has no discount but instead has a 50%
increase in price.</p>
    <a id="zero-level-spells"></a><p><b>0-Level Spells</b>: When multiplying spell levels to
determine value, 0- level spells should be treated as 1/2 level.</p>
    <a id="other-considerations"></a><p><b>Other Considerations</b>: Once you have a final cost
figure, reduce that number if either of the following conditions
applies:</p>
    <a id="item-requires-skill-to-use"></a><p><i>&#8212;Item Requires Skill to Use</i>: Some items require a
specific skill to get them to function. This factor should reduce the
cost about 10%.</p>
    <a id="item-requires-specific-class-or-alignment-to-use"></a><p><i>&#8212;Item Requires Specific Class or Alignment to Use</i>: Even
more restrictive than requiring a skill, this limitation cuts the cost
by 30%.</p>
    <p>Prices presented in the magic item descriptions (the gold
piece value following the item&#8217;s caster level) are the market value,
which is generally twice what it costs the creator to make the item.</p>
    <p>Since different classes get access to certain spells at
different levels, the prices for two characters to make the same item
might actually be different. An item is only worth two times what the
caster of lowest possible level can make it for. Calculate the market
price based on the lowest possible level caster, no matter who makes
the item.</p>
    <p>Not all items adhere to these formulas directly. The reasons
for this are several. First and foremost, these few formulas aren&#8217;t
enough to truly gauge the exact differences between items. The price of
a magic item may be modified based on its actual worth. The formulas
only provide a starting point. The pricing of scrolls assumes that,
whenever possible, a wizard or cleric created it. Potions and wands
follow the formulas exactly. Staffs follow the formulas closely, and
other items require at least some judgment calls.</p>
    <a id="masterwork-items"></a><h5>MASTERWORK ITEMS</h5>
    <p className="initial">Masterwork items are extraordinarily well-made
items. They are more expensive, but they benefit the user with improved
quality. They are not magical in any way. However, only masterwork
items may be enhanced to become magic armor and weapons. (Items that
are not weapons or armor may or may not be masterwork items.)</p>
    <a id="creating-magic-armor"></a><h5>CREATING MAGIC ARMOR</h5>
    <p className="initial">To create magic armor, a character needs a
heat source and some iron, wood, or leatherworking tools. He also needs
a supply of materials, the most obvious being the armor or the pieces
of the armor to be assembled. Armor to be made into magic armor must be
masterwork armor, and the masterwork cost is added to the base price to
determine final market value. Additional magic supplies costs for the
materials are subsumed in the cost for creating the magic armor&#8212;half
the base price of the item.</p>
    <p>Creating magic armor has a special prerequisite: The creator&#8217;s
caster level must be at least three times the enhancement bonus of the
armor. If an item has both an enhancement bonus and a special ability,
the higher of the two caster level requirements must be met.</p>
    <p>Magic armor or a magic shield must have at least a +1
enhancement bonus to have any of the abilities listed on Table: Armor
Special Abilities and Table: Shield Special Abilities.</p>
    <p>If spells are involved in the prerequisites for making the
armor, the creator must have prepared the spells to be cast (or must
know the spells, in the case of a sorcerer or bard), must provide any
material components or focuses the spells require, and must pay any XP
costs required for the spells. The act of working on the armor triggers
the prepared spells, making them unavailable for casting during each
day of the armor&#8217;s creation. (That is, those spell slots are expended
from his currently prepared spells, just as if they had been cast.)</p>
    <p>Creating some armor may entail other prerequisites beyond or
other than spellcasting. See the individual descriptions for details.</p>
    <p>Crafting magic armor requires one day for each 1,000 gp value
of the base price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Magic Arms and Armor.</p>
    <a id="creating-magic-weapons"></a><h5>CREATING MAGIC WEAPONS</h5>
    <p className="initial">To create a magic weapon, a character needs a
heat source and some iron, wood, or leatherworking tools. She also
needs a supply of materials, the most obvious being the weapon or the
pieces of the weapon to be assembled. Only a masterwork weapon can
become a magic weapon, and the masterwork cost is added to the total
cost to determine final market value. Additional magic supplies costs
for the materials are subsumed in the cost for creating the magic
weapon&#8212;half the base price given on Table: Weapons, according to the
weapon&#8217;s total effective bonus.</p>
    <p>Creating a magic weapon has a special prerequisite: The
creator&#8217;s caster level must be at least three times the enhancement
bonus of the weapon. If an item has both an enhancement bonus and a
special ability the higher of the two caster level requirements must be
met.</p>
    <p>A magic weapon must have at least a +1 enhancement bonus to
have any of the abilities listed on Table: Melee Weapon Special
Abilities or Table Ranged Weapon Special Abilities.</p>
    <p>If spells are involved in the prerequisites for making the
weapon, the creator must have prepared the spells to be cast (or must
know the spells, in the case of a sorcerer or bard) but need not
provide any material components or focuses the spells require, nor are
any XP costs inherent in a prerequisite spell incurred in the creation
of the item. The act of working on the weapon triggers the prepared
spells, making them unavailable for casting during each day of the
weapon&#8217;s creation. (That is, those spell slots are expended from his
currently prepared spells, just as if they had been cast.)</p>
    <p>At the time of creation, the creator must decide if the weapon
glows or not as a side-effect of the magic imbued within it. This
decision does not affect the price or the creation time, but once the
item is finished, the decision is binding.</p>
    <p>Creating magic double-headed weapons is treated as creating
two weapons when determining cost, time, XP, and special abilities.</p>
    <p>Creating some weapons may entail other prerequisites beyond or
other than spellcasting. See the individual descriptions for details.</p>
    <p>Crafting a magic weapon requires one day for each 1,000 gp
value of the base price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Magic Arms and Armor.</p>
    <a id="creating-potions"></a><h5>CREATING POTIONS</h5>
    <p className="initial">The creator of a potion needs a level working
surface and at least a few containers in which to mix liquids, as well
as a source of heat to boil the brew. In addition, he needs
ingredients. The costs for materials and ingredients are subsumed in
the cost for brewing the potion&#8212;25 gp x the level of the spell x the
level of the caster. All ingredients and materials used to brew a
potion must be fresh and unused. The character must pay the full cost
for brewing each potion. (Economies of scale do not apply.)</p>
    <p>The imbiber of the potion is both the caster and the target.
Spells with a range of personal cannot be made into potions.</p>
    <p>The creator must have prepared the spell to be placed in the
potion (or must know the spell, in the case of a sorcerer or bard) and
must provide any material component or focus the spell requires.</p>
    <p>If casting the spell would reduce the caster&#8217;s XP total, he
pays the XP cost upon beginning the brew in addition to the XP cost for
making the potion itself. Material components are consumed when he
begins working, but a focus is not. (A focus used in brewing a potion
can be reused.) The act of brewing triggers the prepared spell, making
it unavailable for casting until the character has rested and regained
spells. (That is, that spell slot is expended from his currently
prepared spells, just as if it had been cast.)</p>
    <p>Brewing a potion requires one day.</p>
    <p><b>Item Creation Feat Required</b>: Brew Potion.</p>
    <a id="table-potion-base-price-by-brewers-class"></a><p><b>POTION BASE PRICES (BY BREWER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>25 gp</td>
          <td>25 gp</td>
          <td>25 gp</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>50 gp</td>
          <td>50 gp</td>
          <td>100 gp</td>
          <td>100 gp</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>300 gp</td>
          <td>400 gp</td>
          <td>400 gp</td>
          <td>400 gp</td>
        </tr>
        <tr>
          <td className="last-row">3rd</td>
          <td className="last-row">750 gp</td>
          <td className="last-row">900 gp</td>
          <td className="last-row">1,050 gp</td>
          <td className="last-row">750 gp</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Prices assume that the potion was made
at the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-base-cost-to-brew-a-potion-by-brewers-class"></a><p><b>BASE COST TO BREW A POTION (BY BREWER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>12 gp 5 sp
+1 XP</td>
          <td>12 gp 5 sp
+1 XP</td>
          <td>12 gp 5 sp
+1 XP</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>25 gp
+ 2 XP</td>
          <td>25 gp
+ 2 XP</td>
          <td>50 gp
+4 XP</td>
          <td>50 gp
+4 XP</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>150 gp
+12 XP</td>
          <td>200 gp
+16 XP</td>
          <td>200 gp
+16 XP</td>
          <td>200 gp
+16 XP</td>
        </tr>
        <tr>
          <td className="last-row">3rd</td>
          <td className="last-row">375 gp
+30 XP</td>
          <td className="last-row">450 gp
+36 XP</td>
          <td className="last-row">525 gp
+42 XP</td>
          <td className="last-row">375 gp
+30 XP</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Costs assume that the creator makes the
potion at the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="creating-rings"></a><h5>CREATING RINGS</h5>
    <p className="initial">To create a magic ring, a character needs a
heat source. He also needs a supply of materials, the most obvious
being a ring or the pieces of the ring to be assembled. The cost for
the materials is subsumed in the cost for creating the ring. Ring costs
are difficult to formularize. Refer to Table: Estimating Magic Item
Gold Piece Values and use the ring prices in the ring descriptions as a
guideline. Creating a ring generally costs half the ring&#8217;s market price.</p>
    <p>Rings that duplicate spells with costly material or XP
components add in the value of 50 x the spell&#8217;s component cost. Having
a spell with a costly component as a prerequisite does not
automatically incur this cost. The act of working on the ring triggers
the prepared spells, making them unavailable for casting during each
day of the ring&#8217;s creation. (That is, those spell slots are expended
from his currently prepared spells, just as if they had been cast.)</p>
    <p>Creating some rings may entail other prerequisites beyond or
other than spellcasting. See the individual descriptions for details.</p>
    <p>Forging a ring requires one day for each 1,000 gp of the base
price.</p>
    <p><b>Item Creation Feat Required</b>: Forge Ring.</p>
    <a id="crating-rods"></a><h5>CREATING RODS</h5>
    <p className="initial">To create a magic rod, a character needs a
supply of materials, the most obvious being a rod or the pieces of the
rod to be assembled. The cost for the materials is subsumed in the cost
for creating the rod. Rod costs are difficult to formularize. Refer to
Table: Estimating Magic Item Gold Piece Values and use the rod prices
in the rod descriptions as a guideline. Creating a rod costs half the
market value listed.</p>
    <p>If spells are involved in the prerequisites for making the
rod, the creator must have prepared the spells to be cast (or must know
the spells, in the case of a sorcerer or bard) but need not provide any
material components or focuses the spells require, nor are any XP costs
inherent in a prerequisite spell incurred in the creation of the item.
The act of working on the rod triggers the prepared spells, making them
unavailable for casting during each day of the rod&#8217;s creation. (That
is, those spell slots are expended from his currently prepared spells,
just as if they had been cast.)</p>
    <p>Creating some rods may entail other prerequisites beyond or
other than spellcasting. See the individual descriptions for details.</p>
    <p>Crafting a rod requires one day for each 1,000 gp of the base
price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Rod.</p>
    <a id="creating-scrolls"></a><h5>CREATING SCROLLS</h5>
    <p className="initial">To create a scroll, a character needs a supply
of choice writing materials, the cost of which is subsumed in the cost
for scribing the scroll&#8212;12.5 gp x the level of the spell x the level of
the caster.</p>
    <p>All writing implements and materials used to scribe a scroll
must be fresh and unused. A character must pay the full cost for
scribing each spell scroll no matter how many times she previously has
scribed the same spell.</p>
    <p>The creator must have prepared the spell to be scribed (or
must know the spell, in the case of a sorcerer or bard) and must
provide any material component or focus the spell requires. If casting
the spell would reduce the caster&#8217;s XP total, she pays the cost upon
beginning the scroll in addition to the XP cost for making the scroll
itself. Likewise, a material component is consumed when she begins
writing, but a focus is not. (A focus used in scribing a scroll can be
reused.) The act of writing triggers the prepared spell, making it
unavailable for casting until the character has rested and regained
spells. (That is, that spell slot is expended from her currently
prepared spells, just as if it had been cast.)</p>
    <p>Scribing a scroll requires one day per each 1,000 gp of the
base price.</p>
    <p><b>Item Creation Feat Required</b>: Scribe Scroll.</p>
    <a id="scroll-base-price-by-scribers-class"></a><p><b>SCROLL BASE PRICES (BY SCRIBER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>12 gp 5 sp</td>
          <td>12 gp 5 sp</td>
          <td>12 gp 5 sp</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>25 gp</td>
          <td>25 gp</td>
          <td>50 gp</td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>150 gp</td>
          <td>200 gp</td>
          <td>200 gp</td>
          <td>200 gp</td>
        </tr>
        <tr>
          <td>3rd</td>
          <td>375 gp</td>
          <td>450 gp</td>
          <td>525 gp</td>
          <td>375 gp</td>
        </tr>
        <tr className="odd-row">
          <td>4th</td>
          <td>700 gp</td>
          <td>800 gp</td>
          <td>1,000 gp</td>
          <td>700 gp</td>
        </tr>
        <tr>
          <td>5th</td>
          <td>1,125 gp</td>
          <td>1,250 gp</td>
          <td>1,625 gp</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>6th</td>
          <td>1,650 gp</td>
          <td>1,800 gp</td>
          <td>2,400 gp</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>7th</td>
          <td>2,275 gp</td>
          <td>2,450 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>8th</td>
          <td>3,000 gp</td>
          <td>3,200 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="last-row">9th</td>
          <td className="last-row">3,825 gp</td>
          <td className="last-row">4,050 gp</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Prices assume that the scroll was made
at the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-base-magic-supplies-and-xp-cost-to-scribe-a-scroll-by-scribers-class"></a><p><b>BASE MAGIC SUPPLIES AND XP COST TO SCRIBE A SCROLL (BY
SCRIBER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>6 gp 2 sp 5 cp
+1 XP</td>
          <td>6 gp 2 sp 5 cp
+1 XP</td>
          <td>6 gp 2 sp 5 cp
+1 XP</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>12 gp 5 sp
+1 XP</td>
          <td>12 gp 5 sp
+1 XP</td>
          <td>25 gp
+1 XP</td>
          <td>25 gp
+2 XP</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>75 gp
+6 XP</td>
          <td>100 gp
+8 XP</td>
          <td>100 gp
+8 XP</td>
          <td>100 gp
+8 XP</td>
        </tr>
        <tr>
          <td>3rd</td>
          <td>187 gp 5 sp
+15 XP</td>
          <td>225 gp
+18 XP</td>
          <td>262 gp 5 sp
+21 XP</td>
          <td>187 gp 5 sp
+15 XP</td>
        </tr>
        <tr className="odd-row">
          <td>4th</td>
          <td>350 gp
+28 XP</td>
          <td>400 gp
+32 XP</td>
          <td>500 gp
+40 XP</td>
          <td>350 gp
+28 XP</td>
        </tr>
        <tr>
          <td>5th</td>
          <td>562 gp 5 sp
+45 XP</td>
          <td>625 gp
+50 XP</td>
          <td>812 gp 5 sp
+65 XP</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>6th</td>
          <td>826 gp
+66 XP</td>
          <td>900 gp
+72 XP</td>
          <td>1,200 gp
+96 XP</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>7th</td>
          <td>1,135 gp 5 sp
+91 XP</td>
          <td>1,225 gp
+98 XP</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>8th</td>
          <td>1,500 gp
+120 XP</td>
          <td>1,600 gp
+128 XP</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="last-row">9th</td>
          <td className="last-row">1,912 gp 5 sp
+ 153 XP</td>
          <td className="last-row">2, 025 gp
+162 XP</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Costs assume that the creator makes the
scroll at the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="creating-staffs"></a><h5>CREATING STAFFS</h5>
    <p className="initial">To create a magic staff, a character needs a
supply of materials, the most obvious being a staff or the pieces of
the staff to be assembled.</p>
    <p>The cost for the materials is subsumed in the cost for
creating the staff&#8212;375 gp x the level of the highest-level spell x the
level of the caster, plus 75% of the value of the next most costly
ability (281.25 gp x the level of the spell x the level of the caster),
plus one-half of the value of any other abilities (187.5 gp x the level
of the spell x the level of the caster). Staffs are always fully
charged (50 charges) when created.</p>
    <p>If desired, a spell can be placed into the staff at only half
the normal cost, but then activating that particular spell costs 2
charges from the staff. The caster level of all spells in a staff must
be the same, and no staff can have a caster level of less than 8th,
even if all the spells in the staff are low-level spells.</p>
    <p>The creator must have prepared the spells to be stored (or
must know the spell, in the case of a sorcerer or bard) and must
provide any focus the spells require as well as material and XP
component costs sufficient to activate the spell a maximum number of
times (50 divided by the number of charges one use of the spell
expends). This is in addition to the XP cost for making the staff
itself. Material components are consumed when he begins working, but
focuses are not. (A focus used in creating a staff can be reused.) The
act of working on the staff triggers the prepared spells, making them
unavailable for casting during each day of the staff &#8217;s creation. (That
is, those spell slots are expended from his currently prepared spells,
just as if they had been cast.)</p>
    <p>Creating a few staffs may entail other prerequisites beyond
spellcasting. See the individual descriptions for details.</p>
    <p>Crafting a staff requires one day for each 1,000 gp of the
base price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Staff.</p>
    <a id="creating-wands"></a><h5>CREATING WANDS</h5>
    <p className="initial">To create a magic wand, a character needs a
small supply of materials, the most obvious being a baton or the pieces
of the wand to be assembled. The cost for the materials is subsumed in
the cost for creating the wand&#8212;375 gp x the level of the spell x the
level of the caster. Wands are always fully charged (50 charges) when
created.</p>
    <p>The creator must have prepared the spell to be stored (or must
know the spell, in the case of a sorcerer or bard) and must provide any
focuses the spell requires. Fifty of each needed material component are
required, one for each charge. If casting the spell would reduce the
caster&#8217;s XP total, she pays the cost (multiplied by 50) upon beginning
the wand in addition to the XP cost for making the wand itself.
Likewise, material components are consumed when she begins working, but
focuses are not. (A focus used in creating a wand can be reused.) The
act of working on the wand triggers the prepared spell, making it
unavailable for casting during each day devoted to the wand&#8217;s creation.
(That is, that spell slot is expended from her currently prepared
spells, just as if it had been cast.)</p>
    <p>Crafting a wand requires one day per each 1,000 gp of the base
price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Wand.</p>
    <a id="table-wand-base-price-by-crafters-class"></a><p><b>WAND BASE PRICES (BY CRAFTER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>375 gp</td>
          <td>375 gp</td>
          <td>375 gp</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>750 gp</td>
          <td>750 gp</td>
          <td>1,500 gp</td>
          <td>1,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>4,500 gp</td>
          <td>6,000 gp</td>
          <td>6,000 gp</td>
          <td>6,000 gp</td>
        </tr>
        <tr>
          <td>3rd</td>
          <td>11,250 gp</td>
          <td>13,500 gp</td>
          <td>15,750 gp</td>
          <td>11,250 gp</td>
        </tr>
        <tr className="odd-row">
          <td>4th</td>
          <td>21,000 gp</td>
          <td>24,000 gp</td>
          <td>30,000 gp</td>
          <td>21,000 gp</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Prices assume that the wand was made at
the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="table-base-magic-supplies-and-xp-cost-to-craft-a-wand-by-crafters-class"></a><p><b>BASE MAGIC SUPPLIES AND XP COST TO CRAFT A WAND (BY
CRAFTER'S CLASS)</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Spell Level</th>
          <th style={{
            "width": "20%"
          }}>Clr, Drd, Wiz</th>
          <th style={{
            "width": "20%"
          }}>Sor</th>
          <th style={{
            "width": "20%"
          }}>Brd</th>
          <th style={{
            "width": "20%"
          }}>Pal, Rgr*</th>
        </tr>
        <tr className="odd-row">
          <td>0</td>
          <td>187 gp 5 sp
+15 XP</td>
          <td>187 gp 5 sp
+15 XP</td>
          <td>187 gp 5 sp
+15 XP</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>1st</td>
          <td>375 gp
30 XP</td>
          <td>375 gp
30 XP</td>
          <td>750 gp
+60 XP</td>
          <td>750 gp
+60 XP</td>
        </tr>
        <tr className="odd-row">
          <td>2nd</td>
          <td>2,250 gp
180 XP</td>
          <td>3,000 gp
+240 XP</td>
          <td>3,000 gp
+240 XP</td>
          <td>3,000 gp
+240 XP</td>
        </tr>
        <tr>
          <td>3rd</td>
          <td>5,625 gp
+450 XP</td>
          <td>6,750 gp
+540 XP</td>
          <td>7,785 gp
+630 XP</td>
          <td>5,625 gp
+450 XP</td>
        </tr>
        <tr className="odd-row">
          <td>4th</td>
          <td>10,500 gp
+840 XP</td>
          <td>12,000 gp
+960 XP</td>
          <td>15,000 gp
+1,200 XP</td>
          <td>10,500 gp
+840 XP</td>
        </tr>
        <tr>
          <td colSpan="5">* <i>Caster level is half class level.</i></td>
        </tr>
        <tr>
          <td colSpan="5"><i>Costs assume that the creator makes the
wand at the minimum caster level.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="creating-wondrous-items"></a><h5>CREATING WONDROUS ITEMS</h5>
    <p className="initial">To create a wondrous item, a character usually
needs some sort of equipment or tools to work on the item. She also
needs a supply of materials, the most obvious being the item itself or
the pieces of the item to be assembled. The cost for the materials is
subsumed in the cost for creating the item. Wondrous item costs are
difficult to formularize. Refer to Table: Estimating Magic Item Gold
Piece Values and use the item prices in the item descriptions as a
guideline. Creating an item costs half the market value listed.</p>
    <p>If spells are involved in the prerequisites for making the
item, the creator must have prepared the spells to be cast (or must
know the spells, in the case of a sorcerer or bard) but need not
provide any material components or focuses the spells require, nor are
any XP costs inherent in a prerequisite spell incurred in the creation
of the item. The act of working on the item triggers the prepared
spells, making them unavailable for casting during each day of the
item&#8217;s creation. (That is, those spell slots are expended from his
currently prepared spells, just as if they had been cast.)</p>
    <p>Creating some items may entail other prerequisites beyond or
other than spellcasting. See the individual descriptions for details.</p>
    <p>Crafting a wondrous item requires one day for each 1,000 gp of
the base price.</p>
    <p><b>Item Creation Feat Required</b>: Craft Wondrous Item.</p>
    <a id="intelligent-item-creation"></a><h5>INTELLIGENT ITEM CREATION</h5>
    <p className="initial">To create an intelligent item, a character
must have a caster level of 15th or higher. Time and creation cost are
based on the normal item creation rules, with the market price values
on Table: Item Intelligence, Wisdom, Charisma, and Capabilities treated
as additions to time, gp cost, and XP cost. The item&#8217;s alignment is the
same as its creator&#8217;s. Determine other features randomly, following the
guidelines in the relevant section.</p>
    <a id="adding-new-abilities"></a><h5>ADDING NEW ABILITIES</h5>
    <p className="initial">A creator can add new magical abilities to a
magic item with no restrictions. The cost to do this is the same as if
the item was not magical. Thus, a +1 longsword can be made into a +2
vorpal longsword, with the cost to create it being equal to that of a
+2 vorpal sword minus the cost of a +1 sword.</p>
    <p>If the item is one that occupies a specific place on a
character&#8217;s body the cost of adding any additional ability to that item
increases by 50%. For example, if a character adds the power to confer
invisibility to her ring of protection +2, the cost of adding this
ability is the same as for creating a ring of invisibility multiplied
by 1.5.</p>
    <a id="body-slot-affinities"></a><h5>BODY SLOT AFFINITIES</h5>
    <p className="initial">Each location on the body, or body slot, has
one or more affinities: a word or phrase that describes the general
function or nature of magic items designed for that body slot. Body
slot affinities are deliberately broad, abstract categorizations,
because a hard-and-fast rule can&#8217;t cover the great variety among
wondrous items.</p>
    <p>You can use the affinities in the list below to guide your
decisions on which magic items should be allowed in which body slots.
And when you design your own magic items, the affinities give you some
guidance for what form a particular item should take.</p>
    <p>Some body slots have different affinities for different
specific items. </p>
    <a id="table-body-slot-affinities"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "575px"
    }}>
      <tbody>
        <tr>
          <th>Body Slot</th>
          <th>Affinity</th>
        </tr>
        <tr className="odd-row">
          <td>Headband, helmet</td>
          <td>Mental improvement, ranged attacks</td>
        </tr>
        <tr>
          <td>Hat</td>
          <td>Interaction</td>
        </tr>
        <tr className="odd-row">
          <td>Phylactery</td>
          <td>Morale, alignment</td>
        </tr>
        <tr>
          <td>Eye lenses, goggles</td>
          <td>Vision</td>
        </tr>
        <tr className="odd-row">
          <td>Cloak, cape, mantle</td>
          <td>Transformation, protection</td>
        </tr>
        <tr>
          <td>Amulet, brooch, medallion, necklace, periapt, scarab</td>
          <td>Protection, discernment</td>
        </tr>
        <tr className="odd-row">
          <td>Robe</td>
          <td>Multiple effects</td>
        </tr>
        <tr>
          <td>Shirt</td>
          <td>Physical improvement</td>
        </tr>
        <tr className="odd-row">
          <td>Vest, vestment</td>
          <td>Class ability improvement</td>
        </tr>
        <tr>
          <td>Bracers</td>
          <td>Combat</td>
        </tr>
        <tr className="odd-row">
          <td>Bracelets</td>
          <td>Allies</td>
        </tr>
        <tr>
          <td>Gloves</td>
          <td>Quickness</td>
        </tr>
        <tr className="odd-row">
          <td>Gauntlets</td>
          <td>Destructive power</td>
        </tr>
        <tr>
          <td>Belt</td>
          <td>Physical improvement</td>
        </tr>
        <tr className="odd-row">
          <td>Boots</td>
          <td>Movement</td>
        </tr>
      </tbody>
    </table>
    <p>Wondrous items that don&#8217;t match the affinity for a particular
body slot should cost 50% more than wondrous items that match the
affinity.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      